import './App.css';
import TodoApp from './Components/todoapp/TodoApp'
//import TodosApps from './Components/todoapp/TodosApps';

function App() {
  return (
    <div className="App">
       {/*<TodosApps/>*/} 
       <TodoApp /> 
    </div>
  );
}

export default App;
